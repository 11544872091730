import { Link, useParams } from "react-router-dom";
import Booking from "../Units/Booking";
import { useTranslation } from "react-i18next"; 
import { useEffect, useState} from 'react';
import HelmetLayout from "../../components/HelmetLayout/HelmetLayout";
import appAds from "../../image/appAds.png"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ScrollAnimation from 'react-animate-on-scroll'
export default function Feature() {
  const { t } = useTranslation();
  const featureItem = t("feature_pp.feature_item", { returnObjects: true });
  const a = t("header.feature")
  const b = t("header.title")
  const c = a + " | "+ b
  const [modalShow1, setModalShow1] = useState(false);
    const {featureID} = useParams()
    useEffect(() => {
      if (featureID !== undefined) {
        const elementToScroll = document.getElementById(`${featureID}`);
        if (elementToScroll) {
          elementToScroll.scrollIntoView({ behavior: 'smooth', block:'center', inline:'nearest' });
        }
        
      }
    }, [featureID]);
    function AdsModal(props) {
      return (
        <>
          <Modal
           {...props}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
           centered
           backdrop="static"
           keyboard={false}
          >
            <Modal.Header className="justify-content-end">
              <Button variant="light right" onClick={props.onHide}>
              <i className="fa-solid fa-xmark purple"></i>
            </Button>
            </Modal.Header>
            <Modal.Body>
                      <img width={"100%"} src={appAds} alt="azumaya hotel ads" />
            </Modal.Body>
          </Modal>
        </>
      );
    }
  return (
    <div>
      <HelmetLayout title = {c} />
      <div className="policies__header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>{t("feature_pp.name")}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="is-sticky">
        <Booking />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="re__breadcrumb">
              <ul className="breadcrumb__list">
                <li className="breadcrumb__item">
                  <i className="fa-solid fa-house"></i>
                  <Link to="/"></Link>
                </li>
                <li className="breadcrumb__item">/</li>
                <li className="breadcrumb__item">
                  <Link className="breadcrumb__title" to="/feature">
                    {t('header.feature')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="feature__characteristic">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="feature__content-title">
                <h2>{t("feature_pp.main_title")}</h2>
              </div>
              <div className="feature__content-background">
                <p className="highlight__text">{t("feature_pp.main_content")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feature__type-list">
        <div className="container" id="containerID">
          {featureItem.map((item, index) => 
            {
              if(item.hasLink==true){
                return(
                <ScrollAnimation animateIn="fadeInUp" animateOnce key={index}>
              <div key={item.id} className="feature__type-item" id={item.id}>
              <div className="card mb-3" style={{ border: "none" }}>
                <div className="row g-4">
                  <div className="col-md-4">
                    <img className="img-fluid" src={item.image} alt="" />
                    <div className="feature__number">
                      <span>{item.num}</span>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body" style={{ padding: 0 }}>
                      <h2 className="card-title">{item.title}</h2>
                      <p className="card-text">{item.content}</p>
                      <div className="btn-holder">
                      <div className="btn__detail control-position">
                        <Link to = {item.link}>{t("home_pp.line_btn")}</Link>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </ScrollAnimation>
                )
              }
              if(item.membership==true){
                return(
                <ScrollAnimation animateIn="fadeInUp" animateOnce key={index}>
              <div key={item.id} className="feature__type-item" id={item.id}>
              <div className="card mb-3" style={{ border: "none" }}>
                <div className="row g-4">
                  <div className="col-md-4">
                    <img className="img-fluid" src={item.image} alt="" />
                    <div className="feature__number">
                      <span>{item.num}</span>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body" style={{ padding: 0 }}>
                      <h2 className="card-title">{item.title}</h2>
                      <p className="card-text" style={{whiteSpace:'pre-line'}}>{item.content}</p>
                      <div className="btn-holder">
                      <div className="btn__detail control-position">
                        <Link onClick={() => setModalShow1(true)}>{t("membership.register")}</Link>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AdsModal
                   show = {modalShow1}
                   onHide = {()=> setModalShow1(false)}/>
            </ScrollAnimation>
                )
              }
              if(item.twolink==true){
                return(
                  
                <ScrollAnimation animateIn="fadeInUp" animateOnce key={index}>
              <div key={item.id} className="feature__type-item" id={item.id}>
              <div className="card mb-3" style={{ border: "none" }}>
                <div className="row g-4">
                  <div className="col-md-4">
                    <img className="img-fluid" src={item.image} alt="" />
                    <div className="feature__number">
                      <span>{item.num}</span>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body" style={{ padding: 0 }}>
                      <h2 className="card-title">{item.title}</h2>
                      <p className="card-text" style={{whiteSpace:'pre-line'}}>{item.content}</p>
                      <div>
                      <Link style={{fontSize:'1.4rem', color:'#482979', fontStyle:'italic'}} to = '/contract'>{item.contract}</Link>
                      </div>
                      <div>
                      <Link onClick={() => setModalShow1(true)} style={{fontSize:'1.4rem', color:'#482979', fontStyle:'italic'}} >{item.membership}</Link>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AdsModal
                   show = {modalShow1}
                   onHide = {()=> setModalShow1(false)}/>
            </ScrollAnimation>
                )
              }
              return(
                <ScrollAnimation animateIn="fadeInUp" animateOnce key={index}>
                <div key={item.id} className="feature__type-item" id={item.id}>
                <div className="card mb-3" style={{ border: "none" }}>
                  <div className="row g-4">
                    <div className="col-md-4">
                      <img className="img-fluid" src={item.image} alt="" />
                      <div className="feature__number">
                        <span>{item.num}</span>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body" style={{ padding: 0 }}>
                        <h2 className="card-title">{item.title}</h2>
                        <p className="card-text pre-line">{item.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </ScrollAnimation>
                )
            }
            
            )}
        </div>
      </div>
    </div>
  );
}
