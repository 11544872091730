import React from 'react';
import { useState , useEffect} from 'react';
import { Routes, Route, useParams, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from './i18n'
import Home from "./container/Home/Home";
import Feature from "./container/Feature/Feature";
import Policies from "./container/Policies/Policies";
import Contract from "./container/Contract/Contract";
import Reservation from './container/Reservation/Reservation';
import NewsList from "./components/Home/NewsList";
import News from "./components/Home/News";
import NewsByDate from "./components/Home/NewsByDate";
// import Login from "./container/Login/Login";
// import SignUp from "./container/Login/SignUp";
import PhnomPenhRoomDetail from './components/BranchDetail/phnom-penh-detail/room';
import ThankYou from './container/Reservation/ThankYou';
import CambodiaService from "./container/Service/Service"
import ErrorPage from './container/Units/ErrorPage';
import Feedback from './container/Units/Feedback';
import Reservation_backup from './container/Reservation/Reservation_backup'
import Gallery_PP from './container/Gallery/Gallery';
import ThankYouService from './container/Service/ThankYou';
import ThankYouContract from './container/Contract/ThankYou';
import Cookies from "js-cookie";

function NewRouter(props) {
    const {news} = props;
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
      const [deviceType, setDeviceType] = useState('');

      useEffect(() => {
        const supportedLanguages = ['en', 'ja'];
        const pathSegments = location.pathname.split('/');
        const languageFromPath = pathSegments[1];
    
        if (supportedLanguages.includes(languageFromPath)) {
          if (i18n.language !== languageFromPath) {
            i18n.changeLanguage(languageFromPath);
          }
        } else {
          const defaultLanguage = Cookies.get('i18next') || navigator.language.split('-')[0];
          const languageToUse = supportedLanguages.includes(defaultLanguage) ? defaultLanguage : 'en';
          navigate(`/${languageToUse}${location.pathname}`, { replace: true });
        }
      }, [location.pathname, i18n, navigate]);

      useEffect(() => {
        const handleResize = () => {
          const width = window.innerWidth;
          if (width >= 1024) {
            setDeviceType('pc');
          } else if (width >= 768) {
            setDeviceType('tablet');
          } else {
            setDeviceType('phone');
          }
        };
        handleResize(); 
        window.addEventListener('resize', handleResize); 
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 
    return (
        <section>
            <Routes>
                <Route path="/" element={<Navigate to={`/${i18n.language}/`} replace />} />
                <Route path= "/:lang/" element={<Home deviceType={deviceType} news={news} />} />
                <Route path= "/:lang/q&a/" element={<Policies />} />
                <Route path= "/:lang/reservation/" element={<Reservation deviceType={deviceType} />} />
                <Route path= "/:lang/feature/" element={<Feature/>} />
                <Route path= "/:lang/feature/:featureID" element={<Feature/>} />
                <Route path = "/:lang/service/" element={<CambodiaService deviceType={deviceType} />} />
                <Route path = "/:lang/breakfast/" element={<CambodiaService deviceType={deviceType} />} />
                <Route path = "/:lang/rotenburo/" element={<CambodiaService deviceType={deviceType} />} />
                <Route path = "/:lang/massage/" element={<CambodiaService deviceType={deviceType} />} />
                {/* <Route path="/Service" element={<Service />} /> */}
                <Route path= "/:lang/contract/" element={<Contract />} />
                <Route path = "/:lang/gallery/" element = {<Gallery_PP />} />
                <Route path= "/:lang/thank-you/:selectedCity/" element={<ThankYou />} />
                <Route path = "/:lang/massage/thank-you/:selectedCity/" element={<ThankYouService />} />
                <Route path = "/:lang/contract/thank-you/:selectedCity/" element={<ThankYouContract />} />
                <Route path = "/:lang/news/:title/" element={<News/>} />
                <Route path = "/:lang/news/" element={<NewsList/>} />
                <Route path = "/:lang/news-by-date/:date/" element={<NewsByDate/>} />
                <Route path="/:lang/phnom-penh-detail/room/" element={<PhnomPenhRoomDetail />} />
                {/* <Route path='*' element={<Home news = {news} />} /> */}
                <Route path="/:lang/dev-test" element={<Reservation_backup />} />
            </Routes>
        </section>
    );
}

export default NewRouter;